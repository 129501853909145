import Img from "gatsby-image";
import styled from "styled-components";

const StyledHeaderBg = styled(Img)`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
`;

const StyledContainer = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
`;

const ContentContainer = styled.div`
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  ${({ theme }) => theme.mq.sm`
    text-align: center;
    position: absolute;
    bottom: 0;
    width: 100%;
  `};

  ${({ theme }) => theme.mq.md`
    text-align: center;
    position: absolute;
    width: 100%;
    bottom: 0;
  `};

  ${({ theme }) => theme.mq.lg`
    width: 100%;
    position: absolute;
    z-index: 10;
    left: 0;
    bottom: 0;
    text-align: left;
  `};

  ${({ theme }) => theme.mq.xl`
    width: 100%;
    position: absolute;
    z-index: 10;
    left: 0;
    bottom: 0;
    text-align: left;
  `};

  ${props =>
    props.fluid &&
    `
  `};
`;

const DataContent = styled.div`
  padding: 20px;
  background: rgba(33, 15, 96, 1);
  position: relative;
  z-index: 10;
  ${({ theme }) => theme.mq.md`
      margin-top: -80px;
    `};
`;

const Banner = styled.div`
  background: #210f608f;
  border-radius: 30px 30px 0 0;
  position: absolute;
  bottom: 0;
  text-align: center;
  padding: 10px 0 20px;
  width: 100%;
  ${({ theme }) => theme.mq.sm`
    padding: 5px 0 10px;
    background: #210f608f;
    border-radius: 30px 30px 0 0;
    position: absolute;
    bottom: 0;
  `};

  ${({ theme }) => theme.mq.md`
    padding: 5px 0 10px;
    background: #210f608f;
    border-radius: 30px 30px 0 0;
    position: absolute;
    bottom: 0;
  `};

  ${({ theme }) => theme.mq.lg`
    padding: 40px 0 150px;
    background: #210f608f;
    border-radius: 100px 100px 0 0;
  `};

  ${({ theme }) => theme.mq.xl`
    padding: 40px 0 150px;
    background: #210f608f;
    border-radius: 100px 100px 0 0;
  `};
`;
const DataItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
`;

const ItemLabel = styled.div`
  font-size: 20px;
  color: rgb(29, 201, 155);
  font-weight: 700;
  padding: 0 20px 0 0;
  min-width: 80px;
  text-align: right;
  line-height: 30px;
  border-right: 1px solid rgb(29, 201, 155);
  ${({ theme }) => theme.mq.md`
    font-size: 48px;
    min-width: 170px;
    text-align: right;
    line-height: 60px;
  `};
`;

const ItemValue = styled.div`
  font-size: 12px;
  color: rgba(29, 201, 155, 1);
  padding-left: 20px;

  ${({ theme }) => theme.mq.md`
    font-size: 20px;
  `};
`;

export { StyledHeaderBg, StyledContainer, ContentContainer, DataContent, DataItem, ItemLabel, ItemValue, Banner };

import Img from "gatsby-image";
import styled from "styled-components";

const Icon = styled(Img)`
  width: 106px;
  height: 106px;
  margin: 0 auto;
  // ${({ theme }) => theme.mq.sm`
  //   width: 100%;
  // `}
  // ${({ theme }) => theme.mq.md`
  //   width: 100%;
  // `}
  // ${({ theme }) => theme.mq.lg`
  //   width: 106px;
  // `}
  // ${({ theme }) => theme.mq.xl`
  //   width: 533px;
  // `}
`;

export { Icon };

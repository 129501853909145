import React, { useEffect, useState } from "react";

import Layout from "../components/common/layout/layout";
import SEO from "../components/common/layout/seo";
// import Navigation from "../components/common/navigation/navigation";
import Navigation from "../components/common/navigation/navigation-new";
import Header from "../components/sections/csr/header/header";
import OutOfSchool from "../components/sections/csr/outOfSchool/outOfSchool";
import Objective from "../components/sections/csr/objective/objective";
import PicList from "../components/sections/csr/picList/picList";
import CardList from "../components/sections/csr/cardList/cardList";
import Footer from "../components/sections/footer/index-new";
import dataBuried from "../utils/dataBuried";

const useScrollToBottom = callback => {
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
      setIsScrolling(scrollTop + clientHeight >= scrollHeight);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (isScrolling) {
      callback();
    }
  }, [isScrolling, callback]);
};
const csr = () => {
  useScrollToBottom(() => {
    dataBuried("Website_Homepage_csr_end_show");
  });
  useEffect(() => {
    dataBuried("Website_Homepage_csr_show");
  }, []);
  return (
    <Layout>
      <SEO title="OPay" />
      <Navigation background="white" />
      <Header />
      <OutOfSchool />
      {/* <Objective />
      <PicList />
      <CardList /> */}
      <Footer />
    </Layout>
  );
};

export default csr;

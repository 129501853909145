import Img from "gatsby-image";
import styled from "styled-components";

const StyledImg = styled(Img)`
  
  ${({ theme }) => theme.mq.sm`
    width: 100%;
  `}
  ${({ theme }) => theme.mq.md`
    width: 100%;
  `}
  ${({ theme }) => theme.mq.lg`
    width: 533px;
  `}
  ${({ theme }) => theme.mq.xl`
    width: 533px;
  `}
`;

export { StyledImg };

import React from "react";
import { Text, Box, Flex } from "rebass/styled-components";
import {} from "./style";
import { Container } from "../../../global";

const CardList = () => {
  return (
    <Box padding={["100px 0 60px", null, null, "173px 0 128px"]}>
      <Container>
        <Box
          p={["60px"]}
          sx={{
            boxShadow: "0 0 40px 0 rgba(0, 0, 0, 0.1)",
            marginTop: ["17px"],
          }}
        >
          <Text
            sx={{
              fontSize: ["24px", null, null, "32px"],
              fontWeight: 700,
              lineHeight: ["28px", null, null, "48px"],
              color: "#1DCF9F",
            }}
          >
            Inquiries
          </Text>
          <Flex
            sx={{
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "21px",
              color: "#000000",
              marginTop: ["20px"],
              flexWrap: "wrap",
            }}
          >
            <Text>For inquiries, contact the Corporate Social Responsibility Team at: </Text>
            <Text fontWeight={700}>ngcsr@opay-inc.com</Text>
          </Flex>
        </Box>
        <Box
          p={["60px"]}
          sx={{
            boxShadow: "0 0 40px 0 rgba(0, 0, 0, 0.1)",
            marginTop: ["17px"],
          }}
        >
          <Text
            sx={{
              fontSize: ["24px", null, null, "32px"],
              fontWeight: 700,
              lineHeight: ["28px", null, null, "48px"],
              color: "#1DCF9F",
            }}
          >
            Application Closing Date: 11:59 pm, 30th of June, 2023.
          </Text>
        </Box>
        <Box
          p={["60px"]}
          sx={{
            boxShadow: "0 0 40px 0 rgba(0, 0, 0, 0.1)",
            marginTop: ["17px"],
          }}
        >
          <Text
            sx={{
              fontSize: ["24px", null, null, "32px"],
              fontWeight: 700,
              lineHeight: ["28px", null, null, "48px"],
              color: "#1DCF9F",
            }}
          >
            Note
          </Text>
          <Flex
            sx={{
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "21px",
              color: "#000000",
              marginTop: ["20px"],
            }}
          >
            <Text>
              Proposals are not to be sent to the email stated above. This would lead to the disqualification of the
              applicant.
            </Text>
          </Flex>
        </Box>
      </Container>
    </Box>
  );
};

export default CardList;

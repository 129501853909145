import Img from "gatsby-image";
import styled from "styled-components";

const StyledImg = styled(Img)`
  ${({ theme }) => theme.mq.sm`
    width: 100%;
  `}
  ${({ theme }) => theme.mq.md`
    width: 100%;
  `}
  ${({ theme }) => theme.mq.lg`
    width: 520px;
  `}
  ${({ theme }) => theme.mq.xl`
    width: 520px;
  `}
`;

const Circle = styled.span`
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #000;
  flex-shrink: 0;
  line-height: 28px;
  margin: 0 10px;
`;

const StyledText = styled.div`
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  text-align: ${props => (props.textAlign ? props.textAlign : "left")};
  ${({ theme }) => theme.mq.sm`
    width: 100%;
  `}
  ${({ theme }) => theme.mq.md`
    width: 100%;
    
  `}
  ${({ theme }) => theme.mq.lg`
    max-width: 470px;
    text-align: left;
  `}
  ${({ theme }) => theme.mq.xl`
    max-width: 470px;
    text-align: left;
  `}
`;

const StyledTitle = styled.div`
  color: #1dcf9f;
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  margin-top: 20px;
  ${({ theme }) => theme.mq.lg`
    font-size: 64px;
    line-height: 76px;
    max-width: 500px;
    text-align: left;
    margin-top: 0;
    
  `}
  ${({ theme }) => theme.mq.xl`
    font-size: 64px;
    line-height: 76px;
    max-width: 500px;
    text-align: left;
    margin-top: 0;
  `}
`;

const StyledTitleSmall = styled.div`
  color: #1dcf9f;
  margin-bottom: 16px;
  font-size: 24px;
  line-height: 30px;
  margin-top: 10px;
  text-align: center;
  ${({ theme }) => theme.mq.lg`
    font-size: 32px;
    font-weight: 700;
    line-height: 38px;
    max-width: 490px;
    text-align: left;
    margin-top: 0;
  `}
  ${({ theme }) => theme.mq.xl`
    font-size: 32px;
    font-weight: 700;
    line-height: 38px;
    max-width: 490px;
    text-align: left;
    margin-top: 0;
  `}
`;

export { StyledImg, Circle, StyledText, StyledTitle, StyledTitleSmall };

import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Text, Box, Heading as InfoHeader, Flex } from "rebass/styled-components";
import {
  StyledHeaderBg,
  StyledContainer,
  ContentContainer,
  DataContent,
  DataItem,
  ItemLabel,
  ItemValue,
  Banner,
} from "./style";

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      csrBg: file(sourceInstanceName: { eq: "product" }, name: { eq: "index" }) {
        childImageSharp {
          fluid(maxWidth: 2880) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Box mt={["58px"]}>
      <StyledContainer id="top">
        <StyledHeaderBg objectFit="contain" fluid={data.csrBg.childImageSharp.fluid}></StyledHeaderBg>
        <ContentContainer>
          <Banner>
            <Text padding={["10px", null, null, "19px 31px 34px 60px"]} display="inline-block">
              <Text
                sx={{
                  color: "#fff",
                  fontSize: ["16px", "20px", "56px", "64px"],
                  fontWeight: 700,
                  lineHeight: ["24px", null, null, "80px"],
                  textAlign: ["center", null, null, "center"],
                }}
              >
                Corporate Social Responsibility
              </Text>
              <Text
                sx={{
                  color: "#fff",
                  fontSize: ["12px", "14px", null, "24px"],
                  fontWeight: 500,
                  lineHeight: ["16px", null, null, "40px"],
                  marginTop: "10px",
                  textAlign: ["center", null, null, "center"],
                }}
              >
                As a leading Financial Services company, OPay is committed to addressing National societal priorities
                with a keen focus on education.
              </Text>
            </Text>
          </Banner>
        </ContentContainer>
      </StyledContainer>
      <DataContent>
        <Flex justifyContent="space-between" alignItems={"center"} flexWrap={"wrap"}>
          <Box width={["100%", "100%", "100%", "33%", "33%"]}>
            <DataItem>
              <ItemLabel>2K+</ItemLabel>
              <ItemValue>Children Supported</ItemValue>
            </DataItem>
          </Box>
          <Box width={["100%", "100%", "100%", "33%", "33%"]}>
            <DataItem>
              <ItemLabel>15</ItemLabel>
              <ItemValue>Primary & Secondary Schools Benefitted</ItemValue>
            </DataItem>
          </Box>
          <Box width={["100%", "100%", "100%", "33%", "33%"]}>
            <DataItem>
              <ItemLabel>12</ItemLabel>
              <ItemValue>Communities Reached in 2 Cities</ItemValue>
            </DataItem>
          </Box>
        </Flex>
        <Flex justifyContent="center" alignItems={"center"} flexWrap={"wrap"}>
          <Box width={["100%", "100%", "100%", "33%", "33%"]}>
            <DataItem>
              <ItemLabel>57K+</ItemLabel>
              <ItemValue>Undergraduated Students Reached in 2 Cities</ItemValue>
            </DataItem>
          </Box>
          {/* <Box width={["100%", "100%", "100%", "33%", "33%"]}>
            <DataItem>
              <ItemLabel>57</ItemLabel>
              <ItemValue>Communities Reached in 2 Cities</ItemValue>
            </DataItem>
          </Box> */}
          <Box width={["100%", "100%", "100%", "33%", "33%"]}>
            <DataItem>
              <ItemLabel>2</ItemLabel>
              <ItemValue>Tertiary Institutions Reached in 2 Cities</ItemValue>
            </DataItem>
          </Box>
        </Flex>
      </DataContent>
    </Box>
  );
};

export default Header;

import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Text } from "rebass/styled-components";
import Fade from "react-reveal/Fade";
import { Link } from "gatsby";
import { ServicesContainer, NewContainerBox, Flex, SubTitle, Content, Img, CommonContainer } from "./style";
import { Container } from "../../../global";

import play from "../../../../images/csr/play.png";
import school from "../../../../images/csr/school.png";
import campus from "../../../../images/csr/campus.png";
import arrow from "../../../../images/contact-us/arrow-right.png";
import arrowGreen from "../../../../images/contact-us/arrow-green.png";
import dataBuried from "../../../../utils/dataBuried";

const Paragraph = props => (
  <Text
    variant="body"
    mb="2rem"
    as="p"
    fontSize={["sm", null, "sm", null, "sm"]}
    textAlign="left"
    style={{ textDecoration: "none" }}
    {...props}
  />
);

const OutOfSchool = () => {
  const play4a = key => {
    dataBuried(key);
  };
  const data = useStaticQuery(graphql`
    query {
      outOfSchool: file(sourceInstanceName: { eq: "product" }, name: { eq: "outOfSchool" }) {
        childImageSharp {
          fluid(maxWidth: 533) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Container position="relative" style={{ width: "100vw", maxWidth: "100vw", padding: "0" }}>
      <ServicesContainer>
        <Flex overflow="hidden" style={{ paddingLeft: "5.69vw" }}>
          <NewContainerBox style={{ background: "#F4FCFA" }}></NewContainerBox>
          <Img marginLeft="0">
            <img src={play} />
          </Img>
          <CommonContainer pr="" width="41.7vw" ml="2.3vw">
            <Fade bottom delay={250}>
              <SubTitle
                color="rgba(33, 15, 96, 1) !important"
                width="42vw"
                pl="4.3vw"
                style={{ letterSpacing: "inherit" }}
              >
                Play4aChild
              </SubTitle>
            </Fade>
            <Fade bottom delay={500}>
              <Content mt="16px" width="41vw" pl="4.3vw" color="#210F60">
                Play4aChild serves as a bottom-up strategy to address the challenges faced by out-of-school children in
                Nigeria.
              </Content>
            </Fade>
            <Paragraph width="41.7vw" pl="4.3vw" marginTop={["0", "0", "40px", "40px", "40px"]} mb="50px">
              <Link to={"/play4aChild"} onClick={() => play4a("Website_Homepage_csr_Play4aChild_click")}>
                <img width="40px" style={{ position: "relative" }} src={arrowGreen}></img>
              </Link>
            </Paragraph>
          </CommonContainer>
        </Flex>
        <Flex direction="reverse" overflow="hidden" style={{ paddingLeft: "5.69vw" }}>
          <NewContainerBox></NewContainerBox>
          <CommonContainer width="41.7vw" pl="0" ml="2.3vw">
            <Fade bottom delay={250}>
              <SubTitle color="#FFFFFF !important">OPay Scholarship Programme</SubTitle>
            </Fade>
            <Fade bottom delay={500}>
              <Content mt="16px">
                OPay Scholarship Program aims to address the financial barriers hindering students from pursuing higher
                education.
              </Content>
            </Fade>
            <Paragraph width="41.7vw" marginTop={["0", "0", "40px", "40px", "40px"]} mb="50px">
              <Link to={"/scholarship"} onClick={() => play4a("Website_Homepage_csr_scholarship_click")}>
                <img width="40px" style={{ position: "relative" }} src={arrow}></img>
              </Link>
            </Paragraph>
          </CommonContainer>
          <Img>
            <img src={school} />
          </Img>
        </Flex>
        <Flex overflow="hidden" style={{ paddingLeft: "5.69vw" }}>
          <NewContainerBox style={{ background: "#F4FCFA" }}></NewContainerBox>
          <Img marginLeft="0">
            <img src={campus} />
          </Img>
          <CommonContainer pr="" width="41.7vw" ml="2.3vw">
            <Fade bottom delay={250}>
              <SubTitle
                color="rgba(33, 15, 96, 1) !important"
                width="42vw"
                pl="4.3vw"
                style={{ letterSpacing: "inherit" }}
              >
                OPay Campus Upgrade
              </SubTitle>
            </Fade>
            <Fade bottom delay={500}>
              <Content mt="16px" width="41vw" pl="4.3vw" color="#210F60">
                OPay Campus Upgrade aims to carry out upgrades on identified infrastructure(s) that serves the general
                need of the campus community.
              </Content>
            </Fade>
            <Paragraph width="41.7vw" pl="4.3vw" marginTop={["0", "0", "40px", "40px", "40px"]} mb="50px">
              <Link to={"/campus"} onClick={() => play4a("Website_Homepage_csr_campusUpgrade_click")}>
                <img width="40px" style={{ position: "relative" }} src={arrowGreen}></img>
              </Link>
            </Paragraph>
          </CommonContainer>
        </Flex>
      </ServicesContainer>
    </Container>
  );
};

export default OutOfSchool;
